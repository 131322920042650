var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apply" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "取料申请",
          "left-arrow": "",
          "safe-area-inset-top": "",
          border: false
        },
        on: { "click-left": _vm.leftClick }
      }),
      _c("div", { staticClass: "orderInfo" }, [
        _c(
          "div",
          { staticClass: "orderTop" },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/success_black@2x.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "success" }, [
              _vm._v("提料申请提交成功！")
            ]),
            _c("p", [
              _vm._v(
                "申请已进入审核(审核时间为10:00-17:00)，提交付款凭证截图可加快审核喔！ "
              )
            ]),
            _c(
              "el-button",
              { staticClass: "btn", on: { click: _vm.goSeeSee } },
              [_vm._v("去上传")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "orderBottom" }, [
          _c("div", { staticClass: "orderBottomTitle" }, [_vm._v("订单信息")]),
          _c("div", { staticClass: "orderInfoDetail" }, [
            _c("div", { staticClass: "flex_between" }, [
              _c("div", [_vm._v("提料单号：")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.openNo))])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", [_vm._v("提交时间：")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.createdTime))])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", [_vm._v("买料单数：")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.count))])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", [_vm._v("种类：")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.goods))])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", [_vm._v("总重：")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.weight))])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", [_vm._v("总金额：")]),
              _c("div", [_vm._v(_vm._s(_vm.orderInfo.money))])
            ])
          ])
        ]),
        _c("div", { staticClass: "know", on: { click: _vm.goSeeSee } }, [
          _vm._v(" 查看结算单 ")
        ]),
        _c("div", { staticClass: "backHome", on: { click: _vm.backHome } }, [
          _vm._v(" 返回首页 ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }